(function comp(name) {
    const selector = $(name);

    if (selector.length) {
        selector.on("click", "[data-scroll-down]", function() {
            $('html, body').animate({
                scrollTop: selector.next().offset().top - 68
            }, 1000);
        });
    }

    window.lui.comp[name] = comp;
})(".comp_visual");