(function layout(name) {
    const selector = $(name);

    if (selector.length) {
        let header = selector.find(".wrp_header_body");

        if (! $("#layout_nav").length) {
            selector.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_head"></div><div class="wrp_nav_content"></div></nav>');

            $.importScript(cdnjs.touchswipe, function() {
                $('.ssm-nav').slideAndSwipe();
            });

            let layout_nav = $(document).find("#layout_nav"),
                logo = header.find(".elm_header_logo").clone(),
                nav = header.find(".elm_header_nav").clone();

            layout_nav.find(".wrp_nav_head").append(logo);
            layout_nav.find(".wrp_nav_content").append(nav);
        }

        $.importScript(cdnjs.headroom, function(){
            let headroom = new Headroom(selector[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });

        window.lui.layout[name] = layout;
    }
})("#layout_header");