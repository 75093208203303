(function($) {
    if (!html.hasClass("ie")) {
        doc.on("change", ".part_ui_input", function () {
            if ($(this).hasClass("type--number")) {
                if ($(this).find("input").val() === "" || parseInt($(this).find("input").val()) > $(this).find("input").attr("max")) {
                    $(this).find("input").val(1);
                }
            }
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validateInput();
            }
        });

        doc.on("change", ".part_ui_input.type--file input", function(){
            let elm = $(this);

            elm.closest(".part_ui_input").find("span").text($(this).val().replace(/.*(\/|\\)/, ''));
        });

        $.libInit(".part_ui_input", "lib_input", function(selector) {
            $(selector).each(function () {
                if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("state--invalid")) {
                    $(this).validateInput();
                }
            });
        });

        $.libInit(".part_ui_input.type--date", "lib_input_date", function(selector) {
            $.importStyle(cdnjs.airdatepicker_css);
            $.importScript(cdnjs.airdatepicker, function () {
                $(selector).each(function () {
                    const elm = $(this);
                    const input = elm.find("input")[0]

                    new AirDatepicker(input,{
                        position: "bottom center",
                        isMobile: win.width() < 768,
                        autoClose: true,
                        locale: {
                            days: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
                            daysShort: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
                            daysMin: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
                            months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
                            monthsShort: ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čvn', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'],
                            today: 'Dnes',
                            clear: 'Vymazat',
                            dateFormat: 'dd.MM.yyyy',
                            timeFormat: 'HH:mm',
                            firstDay: 1
                        },
                        onSelect({date, formattedDate, datepicker}) {
                            elm.addClass("state--valid");
                        }
                    })
                });
            })
        });
    }
})(jQuery);
