let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.0.0/js/newlogic-cookieconsent.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.1.2/dist/flickity.pkgd.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.2/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.2/dist/jquery.fancybox.min.css",
    "tilt": "https://cdn.jsdelivr.net/npm/tilt.js@1.2.1/dest/tilt.jquery.min.js",
    "airdatepicker": "https://cdn.jsdelivr.net/npm/air-datepicker@3.2.0/air-datepicker.min.js",
    "airdatepicker_css": "https://cdn.jsdelivr.net/npm/air-datepicker@3.2.0/air-datepicker.css",
    "tomselect": "https://cdn.jsdelivr.net/npm/tom-select@2.2.0/dist/js/tom-select.complete.min.js",
    "tomselect_css": "https://cdn.jsdelivr.net/npm/tom-select@2.2.0/dist/css/tom-select.min.css"
};
