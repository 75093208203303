(function comp(name) {
    const selector = $(name);

    if (selector.length) {
        let carousel = selector.find("[data-slider]");

        $.lib_flickity(function(){
            if (carousel.length) {
                carousel.flickity({
                    groupCells: '25%',
                    cellAlign: 'left',
                    contain: true,
                    pageDots: true,
                    wrapAround: true,
                    prevNextButtons: true
                });
            }
        });
    }

    window.lui.comp[name] = comp;
})(".comp_articles_slider");