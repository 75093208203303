(function($) {
    const reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6Lf0onAgAAAAAD9-rE2A2cc87c5nhFMbtvRayKSG").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6Lf0onAgAAAAAD9-rE2A2cc87c5nhFMbtvRayKSG', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };

    if (!$('.comp_landing').length) {
        const swup = new Swup({
            elements: ['#layout_main', '#layout_header', '#layout_footer'],
            LINK_SELECTOR: `a[href^="${window.location.origin}"]:not([data-no-swup]):not([target="_blank"]), a[href^="/"]:not([data-no-swup]):not([target="_blank"]), a[href^="#"]:not([data-no-swup])`
        });

        swup.on('clickLink', function () {
            $(".ssm-nav-visible").removeClass("ssm-nav-visible");
            $(".ssm-overlay").fadeOut(300);
            $('html').css('overflow','visible');
        });

        swup.on('contentReplaced', function () {
            swup.options.elements.forEach((selector) => {
                Object.keys(window.lui.layout).forEach(function(key) {
                    if ($(key).length) {
                        window.lui.layout[key](key)
                    }
                });
                Object.keys(window.lui.comp).forEach(function(key) {
                    if ($(selector).find($(key)).length) {
                        window.lui.comp[key](key)
                    }
                });
                Object.keys(window.lui.part).forEach(function(key) {
                    if ($(selector).find($(key)).length) {
                        window.lui.part[key](key)
                    }
                });
                Object.keys(window.lui.lib).forEach(function(key) {
                    let s = window.lui.lib[key].selector;
                    if ($(selector).find(s[0]).length) {
                        window.lui.lib[key]($(selector).find(s[0]),s[1])
                    }
                });
            });

            if (typeof fbq !== "undefined") {
                fbq('track', 'PageView');
            }

            if (typeof gtag !== "undefined") {
                let configs = [];
                window.dataLayer.forEach(function(config){
                    if (config[0] === "config") {
                        if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                            configs.push(config[1]);
                            gtag('config', config[1], {
                                'page_title': document.title,
                                'page_path': window.location.pathname + window.location.search
                            });
                        }
                    }
                });
            }

            if (typeof window.dataLayer !== "undefined") {
                window.dataLayer.push({
                    'event': 'VirtualPageview',
                    'virtualPageURL': window.location.pathname + window.location.search,
                    'virtualPageTitle': document.title
                });
            }

            if (typeof window.seznam_retargeting_id !== "undefined") {
                $.getScript("https://c.imedia.cz/js/retargeting.js");
            }

            if (typeof window.lui_cookiesconsent !== "undefined") {
                window.lui_cookiesconsent.fn_cookieconsent_init()
            }

            if (body.hasClass("body--preload")) {
                cssLoaded(function(){
                    body.removeClass("body--preload");
                    setTimeout(function () {
                        body.addClass("body--loaded");
                    }, 300);
                });
            }

        });
    }

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    nl_lib_dialog.init(function(){
        Object.keys(window.lui.lib).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s[0]).length) {
                window.lui.lib[key](dialog_selector.find(s[0]),s[1])
            }
        });
    });

    $.libInit("form[data-recaptcha]", "lib_recaptcha", function(selector){
        reInitGCaptcha();
        setInterval(function(){reInitGCaptcha();}, 150000);
    });

    $.libInit(".part_ui_wsw", "lib_wsw", function(selector){
        $(selector).find("table").each(function() {
            $(this).wrap("<div class='elm_table'></div>");
        });
        $(selector).find(".row--images img").each(function() {
            $(this).wrap(`<a href="${$(this).attr("src")}" class="part_ui_image lib--fancybox-item" data-no-swup=""></a>`);
        });
        $(selector).find('iframe').filter(function(){
            return this.src.match(/youtube\.com/i);
        }).wrap("<div class='elm_video'></div>");
    });

    $.libInit(".lib--parallax", "lib_parallax", function(selector){
        cssLoaded(function(){
            $(selector).find("img:not(.lazyload)").parent().parallax();
        });
    });

    $.libInit("[data-reveal]", "lib_scrollreveal", function() {
        $.lib_scrollreveal();
    });

    $.libInit(".part_ui_link, .part_ui_btn, .part_ui_checkbox, .part_ui_radio", "lib_ripple", function(selector) {
        $(selector).nl_lib_ripple();
    });

    $.libInit("[data-lib-tilt]", "lib_tilt", function(selector) {
        $.importScript(cdnjs.tilt, function(){
            cssLoaded(function(){
                $(selector).tilt({
                    scale: 1.025
                });
            });
        });
    });

    $.libInit("[data-lib-fancybox]", "lib_gallery", function(selector) {
        bodyLoaded(function(){
            if ($(selector).find(".lib--fancybox-item").length) {
                $.importStyle(cdnjs.fancybox_css);
                $.importScript(cdnjs.fancybox, function(){
                    $(selector).each(function(){
                        let id = $(this).data("lib-fancybox");
                        $(this).fancybox({
                            selector: `[data-lib-fancybox="${id}"] .lib--fancybox-item`,
                            transitionEffect: "slide",
                            animationEffect: "zoom",
                            hideScrollbar: false,
                            buttons: [
                                "close"
                            ]
                        });
                    });
                });
            }
        });
    });

    $.libInit("[data-lib-ts]", "lib_ts", function(selector){
        $.importStyle(cdnjs.tomselect_css);
        $.importScript(cdnjs.tomselect, function () {
            $(selector).each(function () {
                $(this).nl_lib_ts();
            })
        });
    });

    (function fn_svgFill(){
        if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
            function resize_svg() {
                setTimeout(function(){
                    $("svg").each(function(){
                        let width_original = $(this).attr("width"),
                            height_original = $(this).attr("height"),
                            width = $(this).parent().innerWidth(),
                            ratio;

                        ratio = width / width_original;

                        $(this).css("height",height_original*ratio);
                    });
                },100);
            }
            cssLoaded(function(){
                resize_svg();
            });
            win.resize(resize_svg);
        }
    })();

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let $el = $(this);

            $.ajax({
                url: $el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                });
            });
        });
    })();

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            function fn_cookiesconsent_event(type, callback) {
                if ("approve" === type) {
                    localStorage.setItem("cookieconsent", "approve");
                    fn_cookiesconsent_append("all");
                } else if ("performance" === type) {
                    localStorage.setItem("cookieconsent", "approve");
                    localStorage.setItem("cookieconsent_type", "performance");
                    fn_cookiesconsent_append("performance");
                } else if ("marketing" === type) {
                    localStorage.setItem("cookieconsent", "approve");
                    localStorage.setItem("cookieconsent_type", "marketing");
                    fn_cookiesconsent_append("all");
                } else if ("decline" === type) {
                    localStorage.setItem("cookieconsent", "decline");
                    localStorage.removeItem("cookieconsent_type");
                    fn_cookiesconsent_delete();
                    if (callback) callback();
                }
            }

            function fn_cookiesconsent_delete() {
                document.cookie.split(";").forEach(function(c) {
                    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                });
            }

            function fn_cookiesconsent_append(type) {
                $("script[data-cookieconsent]").each(function() {
                    var elm = $(this);
                    if ("all" === type) {
                        var script = document.createElement("script");
                        elm.each(function() {
                            $.each(this.attributes, function() {
                                if (this.specified) if (-1 === this.name.indexOf("data-cookieconsent") && -1 === this.name.indexOf("type")) script.setAttribute(this.name, this.value);
                            });
                        });
                        script.innerHTML = elm[0].innerHTML;
                        if (elm.closest("body").length) document.body.appendChild(script); else if (elm.closest("head").length) document.head.appendChild(script);
                        elm.remove();
                    } else {
                        elm = elm.filter('[data-cookieconsent="' + type + '"]');
                        if (elm.length) {
                            var _script = document.createElement("script");
                            elm.each(function() {
                                $.each(this.attributes, function() {
                                    if (this.specified) if (-1 === this.name.indexOf("data-cookieconsent") && -1 === this.name.indexOf("type")) _script.setAttribute(this.name, this.value);
                                });
                            });
                            _script.innerHTML = elm[0].innerHTML;
                            if (elm.closest("body").length) document.body.appendChild(_script); else if (elm.closest("head").length) document.head.appendChild(_script);
                            elm.remove();
                        }
                    }
                });
            }

            !function($) {
                var fn = $(".part_cookie_consent");
                window.lui_cookiesconsent = {};
                window.lui_cookiesconsent.fn_cookieconsent_init = fn_cookieconsent_init;
                function fn_cookieconsent_init() {
                   var type = localStorage.getItem("cookieconsent_type");
                        if (null !== type) {
                            if ("performance" === type) fn_cookiesconsent_append("performance"); else if ("marketing" === type) fn_cookiesconsent_append("all");
                        } else fn_cookiesconsent_append("all");
                }
                if (fn.length) {
                    if (null == localStorage.getItem("cookieconsent")) {
                        fn_cookiesconsent_append("all");
                        setTimeout(function() {
                            cssLoaded(function() {
                                fn.addClass("mod--active mod--animate");
                            });
                        }, 1500);
                    }
                    fn_cookieconsent_init();
                    fn.on("click", function() {
                        fn.addClass("mod--mobile-show");
                    });
                    fn.find("[data-cookies-approve]").on("click", function() {
                        fn_cookiesconsent_event("approve");
                        fn.removeClass("mod--animate");
                        setTimeout(function() {
                            fn.removeClass("mod--active").remove();
                        }, 500);
                    });
                    fn.find("[data-cookies-decline]").on("click", function() {
                        fn_cookiesconsent_event("performance");
                        fn.removeClass("mod--animate");
                        setTimeout(function() {
                            fn.removeClass("mod--active").remove();
                        }, 500);
                    });
                }
            }(jQuery);

            !function($) {
                var fn = $(".part_form_cookies");
                if (fn.length) {
                    if (null !== localStorage.getItem("cookieconsent")) if ("approve" === localStorage.getItem("cookieconsent")) {
                        var type = localStorage.getItem("cookieconsent_type");
                        if (null !== type) if ("performance" === type) fn.find('input[value="performance"]').prop("checked", true); else fn.find('input[value="approve"]').prop("checked", true); else fn.find('input[value="approve"]').prop("checked", true);
                    } else if ("decline" === localStorage.getItem("cookieconsent")) fn.find('input[value="decline"]').prop("checked", true);
                    fn.on("submit", function(e) {
                        e.preventDefault();
                        var value = fn.serializeArray()[0].value;
                        if ("approve" === value) {
                            fn_cookiesconsent_event("marketing");
                            location.reload();
                        } else if ("performance" === value) {
                            fn_cookiesconsent_event("performance");
                            location.reload();
                        } else if ("decline" === value) fn_cookiesconsent_event("decline", function() {
                            location.reload();
                        });
                    });
                }
            }(jQuery);
        }
    })();
})(jQuery);
