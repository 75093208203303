$.fn.nl_lib_ts = function () {
    const elm = $(this);
    const select = elm.find("select")[0];
    const plugins = [];

    elm.addClass('lib--ts');

    if (select.multiple) {
        plugins.push('remove_button')
    }

    const tomselect = new TomSelect(select, {
        create: false,
        plugins: plugins,
        maxOptions: 200,
        onDropdownOpen: function() {
            elm.addClass("state--focus");
            elm.removeClass("state--placeholder");
        },
        onDropdownClose: function() {
            elm.removeClass("state--focus");

            if(!select.multiple) {
                tomselect.blur()
            }
        },
        render:{
            no_results: function( data, escape ){
                return '<div class="no-results">Nebyly nalezeny žádné výsledky</div>';
            },
        }
    })
}
