//____ PARALLAX, NOTES: apply on image wrapper ____ //
//____ AUTHOR: materializecss.com ____ //
$.fn.parallax = function () {
    let window_width = win.width();

    return this.each(function(i) {
        let elm = $(this);
        function updateParallax(initial, initial_img_height) {
            let container_height;
            if (window_width < 960) {
                return false;
            } else {
                container_height = (elm.height() > 0) ? elm.height() : 500;
            }
            let img = elm.children("img").first(),
                img_height = img.height(),
                parallax_dist = img_height - container_height,
                bottom = elm.offset().top + container_height,
                top = elm.offset().top,
                scrollTop = win.scrollTop(),
                windowHeight = 800,
                windowBottom = scrollTop + windowHeight,
                percentScrolled = (windowBottom - top) / (container_height + windowHeight),
                parallax = Math.round((parallax_dist * percentScrolled));

            if (initial) {
                img_height = initial_img_height;
                parallax_dist = img_height - container_height;
                parallax = Math.round((parallax_dist * percentScrolled));
            }
            if ((bottom > scrollTop) && (top < (scrollTop + windowHeight))) {
                img.css('transform', "translate3D(0," + parallax + "px, 0)");
            }

        }
        elm.children("img").beforeImgLoad(function(width, height) {
            cssLoaded(function(){
                updateParallax(true,height);
                elm.children("img").css("opacity","1");
            });
        });

        win.scroll(function() {
            window_width = win.width();
            updateParallax(false,false);
        });

        win.resize(function() {
            window_width = win.width();
            elm.children("img").css("opacity","1");
            updateParallax(false,false);
        });
    });
};
