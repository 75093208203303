(function part(name) {
    const selector = $(name);

    if (selector.length) {
        selector.on('change','[data-toggle]', function (e) {
            const el = $(this);
            const target = selector.find(`[data-target="${el.data('toggle')}"]`);
            const behavior = (el.data('toggle-behavior') === 'invert') ? 'invert' : 'normal';

            if(target.length) {
                if(behavior === 'normal') {
                    if(el.prop('checked')) {
                        target.show();
                        target.find('input:not([type="hidden"]), textarea, select').each(function () {
                            $(this).attr('required','required');
                        })
                    } else {
                        target.hide();
                        target.find('input:not([type="hidden"]), textarea, select').each(function () {
                            $(this).attr('required',null);
                        })
                    }
                }

                if(behavior === 'invert') {
                    if(el.prop('checked')) {
                        target.hide();
                        target.find('input:not([type="hidden"]), textarea, select').each(function () {
                            $(this).attr('required',null);
                        })
                    } else {
                        target.show();
                        target.find('input:not([type="hidden"]), textarea, select').each(function () {
                            $(this).attr('required','required');
                        })
                    }
                }
            }
        });

        selector.on('change', '[data-radio="toggleContact"]', function (e) {
            const el = $(this);
            const type = el.val();
            const targets = el.closest('[data-section="contact"]').find('[data-target="contactRelative" ]');

            if(type === 'relative') {
                targets.each(function () {
                    const target = $(this);
                    target.show();
                })
            } else {
                targets.each(function () {
                    const target = $(this);
                    target.hide();
                })
            }
        })

        selector.on('change', '[data-select="type"]', function (e) {
            const el = $(this);
            const type = el.val();

            el.closest('[data-section="entrant"]').find('[data-target="typeHospital"]').hide()
            el.closest('[data-section="entrant"]').find('[data-target="typeOther"]').hide()

            switch(type) {
                case 'hospital':
                    el.closest('[data-section="entrant"]').find('[data-target="typeHospital"]').show()
                    break;

                case 'other':
                    el.closest('[data-section="entrant"]').find('[data-target="typeOther"]').show()
                    break;

                default:
                    break;
            }
        })

        selector.on('input','[data-input="lockMedical"]', function (e) {
            const el = $(this);
            const targets = el.closest('[data-section="medical"]').find('[data-target="lockMedical" ]');

            if(el.val().length === 0) {
                targets.each(function() {
                    $(this).prop("disabled",false)
                })
            } else {
                targets.each(function() {
                    $(this).prop("disabled",true)
                })
            }
        })
    }

    window.lui.part[name] = part;
})(".part_form_voucher");
