(function comp(name) {
    const selector = $(name);

    if (selector.length) {
        selector.on("click", "[data-map-subregion]", function() {
            window.location.href = $(this).data("map-subregion");
        });

        selector.on("click", "[data-map-marker]", function() {
            window.location.href = $(this).data("map-marker");
        });

        selector.on("mouseenter", "[data-map-marker]", function () {
            const el = $(this);
            const rect = el[0].getBoundingClientRect();
            el[0].style.pointerEvents = 'none';
            const region = document.elementFromPoint(rect.x + (rect.width / 2), rect.y + rect.height);
            el[0].style.pointerEvents = null;
            region.classList.add("state--active")
        })

        selector.on("mouseleave", "[data-map-marker]", function () {
            const el = $(this);
            const rect = el[0].getBoundingClientRect();
            el[0].style.pointerEvents = 'none';
            const region = document.elementFromPoint(rect.x + (rect.width / 2), rect.y + rect.height);
            el[0].style.pointerEvents = null;
            region.classList.remove("state--active")
        })
    }

    window.lui.comp[name] = comp;
})(".comp_text_map");
