function fn_lib_dialog() {
    const dialog = $("[data-dialog]");

    function fn_lib_dialog_show(data,callback) {
        doc.find(".lib--dialog .part_dialog").remove();
        if (!doc.find(".lib--dialog").length) {
            body.addClass("body--no-scroll").append('<div class="lib--dialog"><div class="elm_dialog_background"></div></div>');
        }
        doc.find(".lib--dialog").append(data).fadeIn(300);
        doc.find(".lib--dialog .part_dialog").focus();
        if (callback) {
            callback();
        }
    }

    function fn_lib_dialog_hide(callback) {
        body.removeClass("overflow--no-scroll");
        dialog.removeClass("state--active");
        doc.find(".lib--dialog").fadeOut(300);

        setTimeout(function () {
            doc.find(".lib--dialog").remove();
            if (callback) {
                callback();
            }
        }, 500);
    }

    function fn_lib_dialog_init(callback) {
        doc.on('click', '[data-dialog]', function (e) {
            e.preventDefault();
            let el = $(this);
            el.addClass("state--active");

            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: el.data('dialog')
            }).done(function(data) {
                fn_lib_dialog_show(data.dialog, callback);
            });
        });

        if (body.filter("[data-dialog-open]").length) {
            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: body.filter("[data-dialog-open]").data('dialog-open')
            }).done(function(data) {
                bodyLoaded(function(){
                    fn_lib_dialog_show(data.dialog, callback);
                });
            });
        }

        doc.on("click", ".elm_dialog_background, [data-dialog-close]", function (e) {
            e.preventDefault();
            fn_lib_dialog_hide();

            const d = new Date();
            d.setTime(d.getTime() + (7*24*60*60*1000));
            let expires = "expires="+ d.toUTCString();
            document.cookie = "dialog=1;" + expires + ";path=/";
        });
    }

    return {
        open: fn_lib_dialog_show,
        close: fn_lib_dialog_hide,
        init: fn_lib_dialog_init
    };
}
const nl_lib_dialog = fn_lib_dialog();