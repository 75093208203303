(function comp(name) {
    const selector = $(name);

    if (selector.length) {
        win.on("scroll",function(){
            let elm = selector.find("[data-load-more]");

            if (elm.length) {
                let window_top = win.scrollTop(),
                    window_bottom = window_top + win.height(),
                    elm_top = elm.offset().top,
                    elm_bottom = elm_top + elm.height();

                if ((window_top < elm_top) && (window_bottom > elm_bottom + 96)) {
                    if (!elm.hasClass("is--visible")) {
                        elm.trigger("click");
                        elm.addClass("is--visible");
                    }
                }
            }
        });
    }

    window.lui.comp[name] = comp;
})(".comp_articles");